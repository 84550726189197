.custom-header-redirect {
  font-weight: bold !important;
  color: #fff;
  font-size: 15px;
  background-color: #254336;
  border: 0px;
}
.custom_header_redirect {
  background-color: #fff;
}

.select_items {
  border: 1px solid #fff !important;
  border-radius: 4px !important;
}
.textfield_select {
  border: 1px solid #fff !important;
  border-radius: 4px !important;
}
 .css-1x5jdmq{
  padding: 3.5px 14px !important;
 }
 /* .css-qiwgdb.MuiSelect-select {
  border: 1px solid #fff !important;
 } */
 /* .css-fvipm8{
  border: 1px solid #fff !important;
 }
 .css-1pn2x5c, .css-1v4ccyo {
  border: 1px solid #fff;
  border-radius: 4px !important;
 }
 .css-1pn2x5c {
  border-radius: 4px !important;
  border: 1px solid #fff;
}
.css-1v4ccyo {
  border-radius: 4px !important;
  border: 1px solid #fff;
} */ 
 /* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:hover{
  border: 1px solid #fff !important;
 }
.App.user_theme_white .contant_box label[data-shrink="false"] + .MuiInputBase-formControl .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:hover{
  border: 1px solid #fff !important;
} */
.MuiBox-root.css-26099x {
  background: linear-gradient(179deg, rgb(37 67 54) -12%, rgb(255 255 255) 62%);
}
.css-1q2h7u5.Mui-selected {
    color: #0c367a !important;
}
.css-1q2h7u5, .css-1q2h7u5.Mui-selected {
    color: #0c367a !important;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1q2h7u5 {
    color: #0c367a !important;
}
.manage_top_header {
    box-shadow: rgb(206, 206, 206) 0px 0px 4px -1px !important;
    background-color: rgba(253, 253, 253, 0.97) !important;
    background-color: #fff !important;
    /* background-color: #0c367a !important; */
    /* background-color: #0F67B1 !important; =====blue===*/
    /* background-color: #254336 !important; */
    /* background-color: #DAD3BE !important; */
    /* border-bottom: 1px solid #0a2c1e; */
  }
  .tabbs{
    display: block !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 54px !important;
  }
  .campaign_add_btn:hover{
     background: #ff6800  !important;
  }
  
  .redirect_tab {
    /* background-color: #fff; */
    /* background-color: #96C9F4; */
    /* background-color: #6B8A7A; */
    background-color: #254336;
    min-height: 54px !important;
    /* border-radius: 0px 0px 25px 25px; */
  }
  .redirect_header_row {
    border-bottom: 0px solid #f0f0f0 !important;
    /* background-color: #fff; */
    background-color: #e2e8f04d;
  }
 .tabbs {
    color: #fff !important;
    font-weight: 400 !important;
  }
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    /* color: #0c367a !important; */
    color: #fff !important;
    font-weight: 600;
    padding: 12px 8px !important;
  }

  .manage_call {
    font-size: 15px !important;
    /* color: #fff !important; */
    color: #000 !important;
    cursor: inherit !important;
  }
  #transition-modal-title {
    color: #133325 !important;
}
  .dropbtn {
    color: white;
    border: none;
    background: transparent !important;
  }
  .redirect_custom-header {
    font-weight: bold !important;
    color: #fff;
    font-size: 15px;
    /* background-color: #3FA2F6 !important; */
    /* background-color: #0F67B1 !important; */
    background-color: #254336 !important;
    border: 0px;
  }

  
  section.sidebar-sec {
    /* background-color: #EAEAEA73;
    background-color: #EEEEEE24;
    background-color: #e2e8ee70; */
    /* background-color: #EAEAEA7D; */
    /* background-color: #F4F4F438; */
    background-color: #e2e8f04d;
    padding: 40px 0;
    min-height: 100vh !important;
    max-height: 100% !important;
  }
  .cntnt_title h3 {
    font-size: 25px;
    text-transform: capitalize;
    font-weight: 400;
    color: #254336;
  }
  .all_button_clr:hover{
    background: linear-gradient(180deg, #ffa841 0%, #fa783f 100%) !important;
  transition: 0.2s;
  }

  .pie-container {
    /* background-color: #f0f0f0; */
    /* background-color: #fff; */
    /* background-color: #ece3c7; */
    padding: 2px;
    border-radius: 10px;
    /* box-shadow: rgba(41, 48, 66, 0.05) 0px 0px 0.875rem; */
  }
  .chart-container {
    /* background-color: #fff; */
    /* background-color: #ece3c7; */
    background-color: #f0f0f0;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 25px;
    border-radius: 10px;
    /* box-shadow: rgba(41, 48, 66, 0.05) 0px 0px 0.875rem; */

  }
  .MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-ptkaw2-MuiDataGrid-root {
    /* background-color: #fff; */
    background-color: #ece3c7;
  }
  .redirect_all_button_clr {
    padding: 10px !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    /* background-color: rgb(245, 117, 29) !important; */
    background-color: rgb(37, 67, 54) !important;
    color: rgb(255, 255, 255) !important;
    margin-left: auto;
    margin-right: 15px;
  }
  .redirect_all_button_clr:hover {
    background: linear-gradient(180deg, #ffa841 0%, #fa783f 100%) !important;
    transition: 0.2s;
}
.css-1q2h7u5, .css-1q2h7u5.Mui-selected, button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1q2h7u5 {
  color: #ffffff !important;
}
  
  .dashboard_innr_all_detail ul li button:hover {
    border: none;
    color: #fff;
    padding: 5px 15px;
    /* border: 1px solid #0c3477; */
    font-size: 15px;
    /* box-shadow: 0px 0px 4px -1px #000;
    background: linear-gradient(180deg, #6b8a7a 0%, #254336 100%) !important; */
    background: linear-gradient(180deg, #ffa841 0%, #fa783f 100%) !important;
    transition: 0.2s;
  }
  

  /* =====scrollbar-css===== */

  /* Firefox */
  /* #admin2 */
   *{
    /* scrollbar-width: thin; */
    scrollbar-width: auto; 
    /* scrollbar-color: #0F67B1 #DFE9EB !important; */
    /* scrollbar-color: #254336 #DFE9EB !important; */
    scrollbar-color: #254336 #DFE9EB ;
  }
  
  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    /* border-radius: 5px; */
    background-color: #DFE9EB;
  }
  
  *::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
  }
  
  *::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
  }
  
  *::-webkit-scrollbar-thumb {
    /* border-radius: 5px; */
    /* background-color: #0F67B1 !important; */
    /* background-color: #254336 !important; */
    background-color: #254336 !important;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #62A34B;
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #62A34B;
  }

@media (max-width: 810.98px) { 
  .MuiTabs-root.nav_bar_horizontal.MuiTabs-scroller.MuiTabs-flexContainer {
    overflow: scroll !important;
    width: 100% !important;
  }
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    overflow: scroll !important;
    width: fit-content;
  }
  
 
}

@media (max-width:576px){
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    overflow: scroll !important;
    width: fit-content;
  }
  .all_button_clr {
    padding: 10px !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    background-color: rgb(245, 117, 29) !important;
    background-color: rgb(37, 67, 54) !important;
    color: rgb(255, 255, 255) !important;
    margin-left: auto;
    /* margin-right: 9px !important; */
    margin: 4px !important;
  }
  .all_button_clr:hover{
    background: linear-gradient(180deg, #ffa841 0%, #fa783f 100%) !important;
  transition: 0.2s;
  }
  .dashboard_innr_all_detail ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    width: 100% !important;
  }
  .arrow_bttn {
    justify-content: end;
  }
  .call_icon {
    color: #f5751D !important;
    font-size: 19px !important;
  }
  sub.live_icon {
    position: relative;
    bottom: 14px;
    right: 7px;
    background-color: #f6f1e9;
    padding: 2px 3px;
    border-radius: 22px;
    color: #07285d;
    font-size: 13px !important;
  }
  .chart-container{
    margin-top: 10px;
  }
  .redirect_tab {
    min-height: 54px !important;
  }
  section.sidebar-sec {

    padding: 120px 0;
    min-height: 100vh !important;
    max-height: 100% !important;
  }
}

@media (max-width: 1024px) {
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    /* color: #0c367a !important; */
    color: #fff !important;
    font-weight: 600;
    padding: 12px 8px !important;
    font-size: 12px !important;
}
.tabbs{
  font-size: 12px !important;
  padding: 12px 8px !important;
}
.nav_icn {
  font-size: 20px;
}
}