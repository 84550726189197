// =========Usertheme_Start===>
.App.user_theme_white {
  .contant_box {
    margin: 0 auto;
    background-color: #182e24;
    color: #f1f1f1;
    padding-top: 0 !important;
    .css-165mck1 {
      display: inline-flex;
      flex-direction: column;
      position: relative;
      min-width: 0px;
      padding: 0px;
      margin: 0px;
      /* border: 0px; */
      vertical-align: top;
      border: 1px solid #fff !important;
    }
    .select_date_items .MuiInputBase-formControl {
      border: 1px solid #fff !important;
      border-radius: 4px !important;
    }
    .css-9u8lb4 {
      color: #fff !important ;
    }
    .css-1tgkg2a {
      width: 300px;
      border: 1px solid #fff !important;
      border-radius: 5px;
    }
    .css-1o6xmxv > .MuiTextField-root {
      min-width: auto;
      width: 99% !important;
      border: 1px solid #fff;
      border-radius: 4px;
      padding: 4px 0;
      margin: 2px 0;
    }
    .select_items {
      border: 1px solid #fff !important;
      border-radius: 4px !important;
    }

    .css-1o6xmxv {
      border: 1px solid #fff !important;
      border-radius: 5px !important;
      margin-top: 4px !important;
    }

    .jss24 .MuiInputBase-root {
      border: 1px solid #fff !important;
    }
    padding-top: 15px;

    .select_items {
      border: 1px solid #fff !important;
      border-radius: 4px !important;
    }
    .custom_header_redirect {
      background-color: #182e24;
    }
    .redirect_all_button_clr {
      background-color: rgb(245, 117, 29) !important;
    }
    .sidebar-sec {
      background-color: #182e24;
    }

    .css-oo9unz-MuiDataGrid-root {
      background-color: #182e24 !important;
    }
    .MuiDataGrid-autoHeight.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-ptkaw2-MuiDataGrid-root {
      background-color: #182e24 !important;
    }
    .vertical-bdr-line {
      border-right: 1px solid #224234;
    }

    .header_cps button {
      color: #fff !important;
    }
    .all_button_clr {
      background-color: rgb(245 117 29) !important;
    }
    .pie-container h4 {
      color: #fff;
    }
    main.right_sidebox.mobile_top_pddng.MuiBox-root.css-1v5u2yv {
      background-color: #182e24;
    }

    label[data-shrink="false"]
      + .MuiInputBase-formControl
      .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      border: 1px solid #fff;
      border-radius: 3px;
    }
    .css-oo9unz-MuiDataGrid-root.MuiDataGrid-autoHeight {
      height: auto;
      background: #182e24;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
      color: #fff !important;
    }

    /* Firefox */
    // #admin1

    body .App.theme_white .contant_box::-webkit-scrollbar-track {
      background: #4a5160; /* Background of the scrollbar track */
    }

    body .App.theme_white .contant_box::-webkit-scrollbar-thumb {
      background-color: #1f2a40; /* Color of the scrollbar thumb */
      border-radius: 10px; /* Roundness of the scrollbar thumb */
      border: 2px solid #4a5160; /* Adds a border around the thumb */
    }

    /* Chrome, Edge and Safari */
    *::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    *::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #dfe9eb;
    }

    *::-webkit-scrollbar-track:hover {
      background-color: #b8c0c2;
    }

    *::-webkit-scrollbar-track:active {
      background-color: #b8c0c2;
    }

    .MuiFormControl-root.MuiTextField-root.makeStyles-formControl-30.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      border: 1px solid #fff;
      border-radius: 4px;
    }

    .css-10jhk8h {
      background-color: #224234 !important;
    }

    .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
      background: #224234 !important;
    }

    main.right_sidebox.user_profile_page {
      background-color: #182e24;
    }
    .makeStyles-formControl-3 .MuiInputBase-root {
      color: #666;
      height: 45px;
      min-width: 120px;
      //border-color: #3c3c3c;
      border-style: solid;
      border-width: 1px;
      justify-content: center;
    }

    // .layout_box{
    //   background-color: #1f2a40;
    // }

    // div{
    //   color: #fff;
    // }
    .select_title {
      background-color: #264236;
      width: 100%;
      text-align: center;
      margin: 0 !important;
    }

    .selectgroup_box {
      border-right: 1px solid #264236;
    }
    .manage_mobile_logo {
      padding: 8px;
      background: linear-gradient(
        180deg,
        rgb(37, 67, 54) 35%,
        rgb(107, 138, 122) 100%
      );
    }
    .select_title span {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
    }
    .form_profile_box {
      background-color: #224234;
    }
    .pdf_div_box {
      background-color: #224234;
    }
    .brk_label {
      color: #fff;
      background: transparent !important;
    }
    .list-pdf-file {
      color: #fff !important;
    }
    .card_box {
      text-align: center;
      background-color: #1f2a40;
      border-radius: 0;
      border: 0;
      overflow: visible;
    }
    .manage_top_header1 {
      box-shadow: rgb(20, 27, 43) 0px 0px 4px -1px !important;
      background-color: rgba(253, 253, 253, 0.97) !important;
      background-color: #1f2a40 !important;
      border-bottom: 1px solid #141b2b;
    }
    .manage_top_header {
      background: linear-gradient(
        180deg,
        rgba(37, 67, 54, 1) 35%,
        rgba(107, 138, 122, 1) 100%
      );
      // border-bottom: 1px solid #141b2b;
    }
    // .css-ptkaw2-MuiDataGrid-root{
    //   border-color: rgb(35, 48, 74);
    // }
    .pie-container,
    .chart-container {
      background-color: #224234;
    }
    .rdtCounter{
      color: #000;

      span{
        color: #000;
      }
      .rdtCount{
        color: #000 !important;
      }
    }
    th, td, td{
      color: #000;
    }
    input,
    button,
    ul,
    li,
    a,
    b,
    g,
    text,
    canvas {
      fill: #fff !important;
      color: #fff !important;
    }
    .form_flied_box {
      border: 1px solid #fff !important;
      color: #fff !important;
    }
    label {
      color: #fff;
      background: #182e24;
    }
    .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
      position: relative;
      // background-color: #1f2a40 !important;
    }
    .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
      color: #ffffff !important;
    }
    .custom-header {
      background-color: #224234;
    }
    .menu_icon_button {
      background-color: #254336 !important;
    }
    .makeStyles-borderedRed-2 {
      margin: 4px 4px 1px 4px !important;
      box-shadow: 2px -1px 4px -3px #1f2a40;
      border-left: 3px solid red;
      background-color: #1f2a40;
    }
    .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-withBorderColor {
      border-color: rgb(255, 255, 255) !important;
    }
    .css-rzbl6g-MuiDataGrid-root .MuiDataGrid-withBorderColor {
      border-color: rgb(35, 48, 74) !important;
    }
    .tbl_innr_box {
      border: 1px solid #ffffff;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
      border: 1px solid #fff;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 11.5px 14px !important;
      border: 1px solid #fff !important;
    }
    .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
      border: 1px solid #fff;
      border-radius: 4px;
    }

    .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-y599qu {
      background: #254336 !important;
      display: block;
    }

    .pro-sidebar-inner {
      background: #264236 !important;
    }
    .pro-menu-item:hover {
      background: #1a3429 !important;
    }
    .pro-inner-item {
      border-bottom: 1px solid #141b2b;
    }
    .top_table {
      border: 1px solid #23304a;
    }
    .role_box {
      color: black !important;
    }
    h2,
    h3,
    p,
    div {
      color: #fff !important;
    }
    h5 {
      line-height: 1.5;
      margin: 0;
      font-size: 20px;
    }
    .makeStyles-borderedRed-2 {
      color: #fff !important;
    }
  }
}

// =========Usertheme_End===>

.theme_options {
  max-width: 800px;
  margin: 0 auto;
  text-align: right;
  // padding: 20px 0;
  padding: 10px 0;

  div {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin: 2px 10px;
    border: 1px solid #fff;
    display: inline-block;
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
  .menu_icon_button {
    background-color: #0c367a !important;
  }
}

#theme_white {
  // background-color: #eee;
  color: #eee;
}

.App.theme_white {
  // height: 100vh;
  // background-color: #18191b;

  .contant_box {
    margin: 0 auto;
    background-color: #141b2b;
    color: #f1f1f1;

    // .select_date_items.MuiInputAdornment-outlined{
    //   border: 0px solid #fff !important;
    // }
    .select_date_items .MuiInputBase-formControl {
      border: 1px solid #fff !important;
      border-radius: 4px !important;
    }

    .select_items {
      border: 1px solid #fff !important;
      border-radius: 4px !important;
    }

    // .textfield_select div input{
    // border: 1px solid #fff !important;
    // border-radius: 4px !important;
    // }

    .jss3 .MuiInputBase-root {
      border-color: 0;
    }

    .card_box {
      background-color: #224234 !important;
    }
    .card_button_box {
      background-color: #224234 !important;
    }
    .user_profile_page {
      background-color: #182e24 !important;
    }
    .header_cps button {
      color: #fff !important;
    }
    .all_button_clr {
      background-color: rgb(245 117 29) !important;
    }
    .pie-container h4 {
      color: #fff;
    }
    main.right_sidebox.mobile_top_pddng.MuiBox-root.css-1v5u2yv {
      background-color: #182e24;
    }
    .MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-ptkaw2-MuiDataGrid-root {
      background-color: #1f2a40;
      /* background-color: #ece3c7; */
    }
    label[data-shrink="false"]
      + .MuiInputBase-formControl
      .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      border: 1px solid #fff;
      border-radius: 3px;
    }
    .css-oo9unz-MuiDataGrid-root.MuiDataGrid-autoHeight {
      height: auto;
      background: #182e24;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
      color: #fff !important;
    }

    /* Firefox */
    // #admin1

    body .App.theme_white .contant_box::-webkit-scrollbar-track {
      background: #4a5160; /* Background of the scrollbar track */
    }

    body .App.theme_white .contant_box::-webkit-scrollbar-thumb {
      background-color: #1f2a40; /* Color of the scrollbar thumb */
      border-radius: 10px; /* Roundness of the scrollbar thumb */
      border: 2px solid #4a5160; /* Adds a border around the thumb */
    }

    /* Chrome, Edge and Safari */
    *::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    *::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #dfe9eb;
    }

    *::-webkit-scrollbar-track:hover {
      background-color: #b8c0c2;
    }

    *::-webkit-scrollbar-track:active {
      background-color: #b8c0c2;
    }

    .MuiFormControl-root.MuiTextField-root.makeStyles-formControl-30.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      border: 1px solid #fff;
      border-radius: 4px;
    }

    .css-10jhk8h {
      background-color: #224234 !important;
    }

    .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
      background: #224234 !important;
    }

    main.right_sidebox.user_profile_page {
      background-color: #182e24;
    }
    .makeStyles-formControl-3 .MuiInputBase-root {
      color: #666;
      height: 45px;
      min-width: 120px;
      //border-color: #3c3c3c;
      border-style: solid;
      border-width: 1px;
      justify-content: center;
    }

    // .layout_box{
    //   background-color: #1f2a40;
    // }

    // div{
    //   color: #fff;
    // }
    .select_title {
      background-color: #264236;
      width: 100%;
      text-align: center;
      margin: 0 !important;
    }
    .selectgroup_box {
      border-right: 1px solid #264236;
    }
    .manage_mobile_logo {
      padding: 8px;
      background-color: #23304a;
    }
    .select_title span {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
    }
    .form_profile_box {
      background-color: #224234;
    }
    .pdf_div_box {
      background-color: #224234;
    }
    .brk_label {
      color: #fff;
      background: transparent !important;
    }
    .list-pdf-file {
      color: #fff !important;
    }
    .card_box {
      text-align: center;
      background-color: #1f2a40;
      border-radius: 0;
      border: 0;
      overflow: visible;
    }
    .manage_top_header1 {
      box-shadow: rgb(20, 27, 43) 0px 0px 4px -1px !important;
      background-color: rgba(253, 253, 253, 0.97) !important;
      background-color: #1f2a40 !important;
      border-bottom: 1px solid #141b2b;
    }
    .manage_top_header {
      background: linear-gradient(
        180deg,
        rgba(37, 67, 54, 1) 35%,
        rgba(107, 138, 122, 1) 100%
      );
      // border-bottom: 1px solid #141b2b;
    }
    // .css-ptkaw2-MuiDataGrid-root{
    //   border-color: rgb(35, 48, 74);
    // }
    .pie-container {
      background-color: #224234;
    }
    .datefield_select input{
      color: #fff !important;
    }
    input,
    button,
    ul,
    li,
    a,
    b,
    g,
    text {
      fill: #fff !important;
      color: #fff !important;
    }
    .form_flied_box {
      border: 1px solid #fff !important;
      color: #fff !important;
    }
    label {
      color: #fff;
      background: #182e24;
    }
    .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
      position: relative;
      // background-color: #1f2a40 !important;
    }
    .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
      color: #ffffff !important;
    }
    .custom-header {
      background-color: #224234;
    }
    .menu_icon_button {
      background-color: #f5751d !important;
    }
    .makeStyles-borderedRed-2 {
      margin: 4px 4px 1px 4px !important;
      box-shadow: 2px -1px 4px -3px #1f2a40;
      border-left: 3px solid red;
      background-color: #1f2a40;
    }
    .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-withBorderColor {
      border-color: rgb(255, 255, 255) !important;
    }
    .css-rzbl6g-MuiDataGrid-root .MuiDataGrid-withBorderColor {
      border-color: rgb(35, 48, 74) !important;
    }
    .tbl_innr_box {
      border: 1px solid #ffffff;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
      border: 1px solid #fff;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 11.5px 14px !important;
      border: 1px solid #fff !important;
    }
    .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
      border: 1px solid #fff;
      border-radius: 4px;
    }
    .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaderCheckbox {
      background: #224234 !important;
    }

    // .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-y599qu {
    //   background: #23304a !important;
    //   display: block;
    // }

    .pro-sidebar-inner {
      background: #264236 !important;
    }
    .pro-menu-item:hover {
      background: #1a3429 !important;
    }
    .pro-inner-item {
      border-bottom: 1px solid #141b2b;
    }
    .top_table {
      border: 1px solid #23304a;
    }
    .role_box {
      color: black !important;
    }
    h2,
    h3,
    p,
    div {
      color: #fff !important;
    }
    h5 {
      line-height: 1.5;
      margin: 0;
      font-size: 20px;
    }
    .makeStyles-borderedRed-2 {
      color: #fff !important;
    }
  }
}

.App.theme_blue {
  // height: 100vh;
  //  background-color: #fff;
  .contant_box {
    margin: 0 auto;
    // background-color: #eee;
    // border-radius: 8px;
    // padding: 30px 30px 40px 30px;
    // max-width: 800px;
    h2,
    h5 {
      color: #000;
    }
    p {
      color: #000;
    }
    .jss3 .MuiInputBase-root {
      border: 0;
    }

    .card_box {
      text-align: center;
      background-color: #fff;
      border-radius: 0;
      border: 0;
      overflow: hidden;
    }
    .card_button_box {
      background-color: #fff;
    }
    .pdf_div_box {
      background-color: #fff;
    }
    .body_row {
      background-color: blue;
    }
  }
}

.App.theme_orange {
  background-color: #f4c9b7;
  .contant_box {
    background-color: #f4dfd6;
    h2,
    h5 {
      color: #b64b1e;
    }
    p {
      color: #000;
    }
  }
}

.App.theme_purple {
  background-color: #d0b7f4;
  .contant_box {
    background-color: #d8def4;
    h2,
    h5 {
      color: #ca00b9;
    }
    p {
      color: #000;
    }
  }
}

.App.theme_green {
  background-color: #c3e3c1;
  .contant_box {
    background-color: #e2f6e1;
    h2,
    h5 {
      color: #01db5c;
    }
    p {
      color: #000;
    }
  }
}

.App.theme_black {
  background-color: #5c5c5c;
  .contant_box {
    background-color: #333;
    h2,
    h5 {
      color: #4b4b4b;
    }
    p {
      color: #000;
    }
  }
}

// .permission_select_box{
//   background-color: #1f2a40;
// }
.permission_label {
  color: #fff !important;
}
